.heardCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  border-radius: 10px;
  width: 18%;
  height: 148px;
}
.heardCon .heardConTop {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
}
.heardCon .heardConTop .heardConIcon {
  width: 78px;
  height: 78px;
  margin-top: 20px;
}
.heardCon .heardConTitleText {
  margin-left: 20;
  margin-top: 20;
  font-family: 'PingFang SC';
  font-weight: bold;
  font-size: 18px;
  line-height: 14px;
  width: 72px;
  height: 25px;
}
.heardCon .heardConText {
  font-family: 'PingFang SC';
  font-weight: bold;
  font-size: 34px;
  line-height: 80px;
  color: #333333;
}
.heardCon .heardConBottom {
  height: 28px;
  border-radius: 0px 0px 10px 10px;
  opacity: 0.45;
  width: 100%;
}
.HeardTitle {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 20px;
  color: #071428;
  line-height: 14px;
}
.tableRowBackColor {
  background-color: #F2F4F8;
}
.ant-table-thead > tr > th {
  background-color: #F2F4F8;
  /* 你想要的背景颜色 */
}
