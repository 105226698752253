.logoup {
  text-align: center;
}
.logoup .ant-upload.ant-upload-select-picture-card {
  width: 240px;
  height: 240px;
}
.userTable .ant-table-thead > tr > th {
  text-align: center !important;
}
.tableHiddle {
  display: none;
}
.tableShow {
  display: revert;
}
.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10;
}
