/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.body1 {
  height: 100vh;
  background-size: cover;
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
}
.loginContain {
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  height: 100%;
  flex-direction: row;
  padding: 0px 10%;
  justify-content: space-between;
}
.loginImg {
  padding-top: 15vh;
  width: 45%;
}
.loginBox {
  width: 25vw;
  height: 60vh;
  box-shadow: 0px 0px 10px rgba(121, 121, 121, 0.514);
  background: #ffffff;
  border-radius: 10px;
  padding: 30px;
}
.loginContainMeiHao {
  display: flex;
  position: absolute;
  width: 40vw;
  align-items: center;
  height: 55vh;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px 0px 10px rgba(121, 121, 121, 0.514);
  background: #ffffff;
  border-radius: 30px;
  top: 23vh;
  padding: 0;
  left: 52vw;
}
.loginImgMeiHao {
  height: 55vh;
}
.loginBoxMeiHao {
  width: 25vw;
  height: 55vh;
  padding: 30px;
}
.inputBox {
  background: rgba(224, 224, 224, 0.2);
  border: 1px solid rgba(207, 207, 207, 0.2);
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  width: 80%;
  color: #fff;
  padding-left: 30px;
}
.inputBox::-webkit-input-placeholder {
  color: #fff;
}
.btn {
  background: #006fc8;
  width: 80%;
  color: #fff;
  border: none;
}
.devicestate .ant-badge-status-dot {
  width: 16px;
  height: 16px;
}
.localButton {
  width: 46px;
  height: 46px;
  color: #9c9c9cd9;
}
.localButton:hover {
  background: #4a4a4a;
  color: #b1b1b1;
}
.localButtonClose {
  width: 46px;
  height: 46px;
  color: #9c9c9cd9;
}
.localButtonClose:hover {
  background: #b31515;
  color: #ececec;
}
.localTitle {
  float: right;
  width: 150px;
  height: 46px;
  z-index: 11;
  position: relative;
}
