.file {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-color: #d9d9d9;
}
.file input {
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 0;
  width: 84px;
  height: 32px;
  -ms-filter: 'alpha(opacity=0)';
}
.file:hover {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}
.ant-tabs-content .tableshow {
  width: calc(100vw - 300px) !important;
  height: 100%!important;
}
.ant-tabs-content .tableshow1 {
  width: calc(100vw - 160px) !important;
  height: 100%!important;
}
#devicechart {
  flex: 1 1;
  height: '100%';
  display: flex;
  background-color: #fff;
}
.flexDiv {
  display: flex;
  flex-direction: column;
}
#devicechart .ant-tabs-content {
  height: 100%;
}
#tempEcharts {
  width: 100%;
  height: 100%;
  flex: 1 1;
}
.ant-tabs-tabpane.flexDiv.local {
  width: calc(100vw - 80px) !important;
}
