.localMenu .ant-menu {
  background: #4ACA6D !important;
  color: #fff;
}
.localMenu .ant-menu a {
  color: #fff;
}
.localMenu .ant-menu-horizontal {
  border-bottom: 0px solid #fff;
}
.localMenu .ant-menu-horizontal .ant-menu-item-selected {
  color: #fff;
  background-color: rgba(52, 180, 88, 0.8);
}
.localMenu .ant-menu-horizontal .ant-menu-item-selected a {
  color: #fff;
}
.localMenu .ant-menu-horizontal .ant-menu-item-selected::after {
  border-bottom: 0px solid #fff;
}
.localMenu .ant-menu-horizontal .ant-menu-item-active {
  color: #fff;
  background-color: rgba(52, 180, 88, 0.8);
}
.localMenu .ant-menu-horizontal .ant-menu-item-active a {
  color: #fff;
}
.localMenu .ant-menu-horizontal .ant-menu-item-active ::after {
  border-bottom: 0px solid #fff;
}
.localMenu .ant-menu-horizontal .ant-menu-item:hover::after {
  transition: unset;
  border-bottom: 0px solid #fff;
}
.localMenu .ant-menu-horizontal .ant-menu-item:hover {
  color: #fff;
  background-color: rgba(52, 180, 88, 0.8);
}
.localMenu .ant-menu-horizontal .ant-menu-item:hover a {
  color: #fff;
}
.localMenu .ant-menu-horizontal .ant-menu-item::after {
  transition: unset;
}
.localButton {
  width: 46px;
  height: 46px;
  color: #fff;
}
.localButton:hover {
  background-color: rgba(52, 180, 88, 0.8);
  color: #b1b1b1;
}
.localButtonClose {
  width: 46px;
  height: 46px;
  color: #9c9c9cd9;
}
.localButtonClose:hover {
  background: #b31515;
  color: #ececec;
}
.localTitleCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 46px;
  align-items: center;
  background-color: #fff;
}
.localTitle {
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}
