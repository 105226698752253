.somkerWarning {
  /* Chrome, Safari, Opera */
  /* Standard syntax */
  animation: mymove 5s infinite;
}
@keyframes mymove {
  0% {
    width: 20px;
    height: 20px;
  }
  50% {
    width: 40px;
    height: 40px;
  }
  100% {
    width: 20px;
    height: 20px;
  }
}
