.upData .ant-checkbox-group-item {
  padding: 5px 10px;
}
.upData {
  max-height: 65vh;
  overflow: auto;
}
#DataView {
  background-color: #fff;
}
