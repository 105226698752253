.flexCon {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}
.flexCol {
  display: flex;
  flex-direction: column;
}
