.rowItem {
  margin-bottom: 10px;
  border: 1px solid #fff;
  border-bottom-color: #ccc;
  border-radius: 10px;
}
.rowItem:hover {
  border: 1px solid #4FCBED;
  box-shadow: 0px 5px 0 #ddd;
  border-radius: 10px;
}
.chartLay {
  transition: All 0.4s ease-in-out;
  background-color: #fff;
  padding: 10px 5px;
  margin-right: 8px;
  width: 220px;
  height: 175px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 0 #ccc;
}
.chartLay:hover {
  border: 2px solid #4FCBED !important;
  border-radius: 10px;
  transform: scale(1.1);
  z-index: 99;
}
.chartLay1:hover {
  border: 2px solid #4FCBED !important;
  border-radius: 10px;
  transform: scale(1.1);
  z-index: 99;
}
.chartLay1 {
  position: relative;
  transition: All 0.4s ease-in-out;
  background-color: #fff;
  padding: 10px;
  margin-right: 10px;
  width: 180px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 2px 5px 0 #ccc;
}
.devicestate {
  align-items: center;
}
.devicestate .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}
.devicestate .ant-badge-status-success::after {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.litedivider .ant-divider-horizontal {
  margin: 12px 0px;
}
.nuiscroll {
  padding: 5;
}
.nuiscroll div {
  outline: none;
}
.nuiscroll .rstm-search {
  outline-color: transparent;
}
.nuiscroll .rstm-tree-item-group {
  border-top: 1px solid #f0f0f0;
}
.nuiscroll .rstm-tree-item-group .rstm-tree-item {
  border: none;
  border-left: 4px solid transparent;
}
.nuiscroll .rstm-tree-item-group .rstm-tree-item:hover {
  background: #f5f7fa;
  border-left: 4px solid #409eff;
}
.nuiscroll .rstm-tree-item-group .rstm-tree-item--active {
  background: #f5f7fa;
  box-shadow: none;
  color: #333;
  border-left: 4px solid #409eff;
}
.nuiscroll::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0);
}
/*正常情况下滑块的样式*/
.nuiscroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  /* -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1); */
}
/*鼠标悬浮在该类指向的控件上时滑块的样式*/
.nuiscroll:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  /* -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1); */
}
/*鼠标悬浮在滑块上时滑块的样式*/
.nuiscroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
  /* -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1); */
}
/*正常时候的主干部分*/
.nuiscroll::-webkit-scrollbar-track {
  border-radius: 2px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0); */
  background-color: white;
}
/*鼠标悬浮在滚动条上的主干部分*/
.nuiscroll::-webkit-scrollbar-track:hover {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .4); */
  background-color: rgba(0, 0, 0, 0.01);
}
.gomap :hover {
  cursor: pointer;
}
.ant-select-selector,
.ant-cascader-input {
  border-radius: 10px !important;
}
.notshow {
  display: none;
}
/* 历史数据容器*/
#devicechart {
  flex-direction: column;
  width: 100%;
  height: 100%;
}
#devicechart .ant-spin-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.flexCon {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.smokerAlarm {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 60px solid transparent;
  border-right: 60px solid transparent;
  border-bottom: 80px solid red;
  top: 65px;
  left: 40px;
  animation: smokerMove 5s infinite;
  -webkit-animation: smokerMove 5s infinite;
  color: white;
  font-size: 20px;
}
/* Standard syntax */
@keyframes smokerMove {
  50% {
    opacity: 0;
  }
}
.fontContent {
  font-family: Helvetica;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  line-height: 16px;
}
.fontSecond {
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
  line-height: 12px;
}
#history .ant-modal-header {
  background: rgba(96, 153, 245, 0.39);
}
.fontContentHis {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  line-height: 16px;
}
.showSetting {
  display: none;
}
.chartLay:hover .showSetting {
  display: block;
}
.chartLay:hover .showName {
  display: none;
}
.setItemBack {
  background-color: #F2F4F8;
  padding: 10px 20px;
}
.setItemBack1 {
  padding: 10px 20px;
}
.setFont {
  font-family: PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  line-height: 16px;
}
.setItemCon {
  height: 360px;
  overflow: scroll;
}
