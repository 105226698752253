.ant-menu-vertical {
  max-height: 100vh !important;
}
.leftNav {
  display: flex;
  flex-direction: column;
  background-color: #2E2E2E;
}
.ant-menu {
  background-color: #2E2E2E!important;
  color: #fff !important;
}
