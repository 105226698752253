.socDevice {
  background-color: springgreen;
}
.hideDevice {
  background-color: #c0c0c0;
}
.notshow {
  display: none;
}
.expireLong {
  color: aqua;
}
